jQuery(function($) {
    if ($(window).width() > 991) {
        $('.navbar .nav-item.dropdown').hover(function() {
            $(this).find('.dropdown-menu').addClass('show');
            $(this).addClass('show');
        }, function() {
            $(this).find('.dropdown-menu').removeClass('show');
            $(this).removeClass('show');
        });
    } else {
        $('.navbar .dropdown > a > .caret').click(function(e) {
        	e.preventDefault();
            if ($(this).closest('.nav-item.dropdown ').hasClass('show')) {
	            $(this).closest('.nav-item.dropdown ').removeClass('show');
	            $(this).closest('.nav-item.dropdown ').find('.dropdown-menu').removeClass('show');
            } else {
	            $(this).closest('.nav-item.dropdown ').addClass('show');
	            $(this).closest('.nav-item.dropdown ').find('.dropdown-menu').addClass('show');
            }
        });
    }
});